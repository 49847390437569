import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import './Profile.css';
import axios from 'axios';
import { Avatar } from '@mui/material';
import { FaHeart } from "react-icons/fa";
import { FaComment } from "react-icons/fa";
import PostModal from './PostModal/PostModal';
import { dev } from '../../config/envs';
import { requestLikeCount } from '../../services/socketService';
const baseUsersUrl = dev.usersAPIurl;
const basePostsUrl = dev.catalogAPIurl;


function ProfilePage() {
  const [likeCount, setLikeCount] = useState(0)
  const [updater, setUpdater] = useState(true)
  const [profile, setProfile] = useState({});
  const isFollowing = profile.followers ? profile.followers.includes(localStorage.getItem("userId")) : false;

  let { username } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      await axios.get(`${baseUsersUrl}/profile/${username}`)
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
    }
    fetchData();
  }, [updater])

  const firstCapitalLetter = (string) => {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const followers = profile.followers ? profile.followers.length : 0;
  const following = profile.following ? profile.following.length : 0;
  const postscount = profile.posts ? profile.posts.filter(post => !post.deletedAt).length : 0;
  const postobject = profile.posts ? profile.posts : [];

  const InfoWrapper = ({ title, number }) => {
    return (
      <div className="info__wrapper">
        <h2>{title}</h2>
        <h3>{number}</h3>
      </div>
    )
  }

  const Separator = () => {
    return (
      <div className="divider"></div>
    )
  }

  const FollowButton = ({ isFollowing }) => {
    const myId = localStorage.getItem("userId")
    const isMyProfile = myId === profile._id
    const follow = () => {
      axios.patch(`${baseUsersUrl}/follow/user/${myId}`, {
        user_id: profile._id
      }).then((res) => {
        console.log(res);
        setUpdater(!updater)
      }).catch((err) => {
        console.log(err);
      })
    }

    const unfollow = () => {
      axios.patch(`${baseUsersUrl}/unfollow/user/${myId}`, {
        user_id: profile._id
      }).then((res) => {
        console.log(res);
        setUpdater(!updater)
      }).catch((err) => {
        console.log(err);
      })
    }

    if (isMyProfile) return null;
    return (
      <button onClick={() => {
        isFollowing ? unfollow() : follow()
      }} className="follow__button">
        {isFollowing ? "Seguindo" : "Seguir"}
      </button>
    )
  }

  const PostsGrid = ({ posts }) => {
    const [postsData, setPostsData] = useState([]);
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [selectedPost, setSelectedPost] = useState(null)
    useEffect(() => {
      Promise.all(posts.map(post =>
        axios.get(`${basePostsUrl}/searchPost/${post}`)
          .then(response => response.data)
      )).then(data => {
        setPostsData(data);
      }).catch(error => {
        console.log(error);
      });
    }, [])
    return (
      <div className="posts__grid">
        {
          postsData.map((post) => {
            requestLikeCount(post._id).then((res) => {
              setLikeCount(res)
            })
            if (post.deletedAt) return null;
            return (
              <div key={post._id}>
                <div onClick={() => {
                  setSelectedPost(post)
                  handleOpen()
                }} className="post__card">
                  <img src={post.images[0].imageUrl} alt="" className='postcard__image' />
                  <div className='hover__info'>
                    <FaHeart color='#fff' />
                    <p>{likeCount}</p>
                    <FaComment color='#fff' />
                    <p>{post.interactions.comments.total}</p>
                  </div>
                </div>
                <PostModal post={selectedPost || post} open={open} handleClose={handleClose} />
              </div>
            )
          })
        }
      </div>
    )
  }
  return (
    <>
      <div className="profile__wrapper">
        <div className="header__profile">
          <div className='profile'>
            <Avatar
              sx={{
                width: 146,
                height: 146,
                border: "2px solid #ffbf6b",
              }}
              src={profile.profileImage}
            />
            <h2>@{profile.username}</h2>
            <h1>{firstCapitalLetter(profile.name)}</h1>
            <FollowButton isFollowing={isFollowing} />
          </div>
          <div className="profile__infos">
            <InfoWrapper title="Seguidores" number={followers} />
            <InfoWrapper title="Seguindo" number={following} />
            <InfoWrapper title="Publicações" number={postscount} />
          </div>
        </div>
        <Separator />
        <PostsGrid posts={postobject} />
      </div>
    </>
  )
}

export default ProfilePage;