import "./FeedHome.css"
import axios from "axios"
import React, { useState, useRef, useEffect, useCallback } from "react"
import {
  Avatar
} from "@mui/material"
import { Modal, Box } from "@mui/material"
import { CgClose } from "react-icons/cg"
import { IoCameraOutline } from "react-icons/io5";
import { LiaCatSolid } from "react-icons/lia";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import { Post } from "./PostComponent"
import { dev } from "../../../config/envs"

export default function FeedHome() {
  const [allPosts, setAllPosts] = useState([])
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [catImage, setCatImage] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const username = localStorage.getItem("username")
  const imagesURL = dev.imagesAPIurl
  const catalogURL = dev.catalogAPIurl
  const usersURL = dev.usersAPIurl

  useEffect(() => {
    axios.get(`${imagesURL}/getimg`).then((res) => {
      setCatImage(res.data[0].url)
    }).catch((err) => {
      console.log(err)
    })
    axios.get(`${catalogURL}/allPosts`).then((res) => {
      setAllPosts(res.data || [])
    }
    ).catch((err) => {
      console.log(err)
    })
    axios.get(`${usersURL}/profile/${username}`).then((res) => {
      setProfileImage(res.data.profileImage)
      localStorage.setItem("avatar", res.data.profileImage)
    }).catch((err) => {
      console.log(err)
    })
  }, [shouldUpdate])
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyItems: "space-between",
    height: "70%",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "18px",
    border: "none",
  }

  const CreateNewPost = () => {
    return (
      <div className="new__post">
        <div className="post__header">
          <Avatar
            alt="Profile"
            src={profileImage}
            sx={{ width: 56, height: 56, border: "2px solid #FFD700" }}
          />
          <button onClick={() => {
            handleOpen()
          }} className="new__post__button">Comece uma publicação</button>
        </div>
      </div>
    )
  }

  const ModalCreatePost = () => {
    const [image, setImage] = useState(null)
    const fileInput = useRef()
    const [text, setText] = useState("")

    const handleFileInput = () => {
      fileInput.current.click()
    }

    const handleFileChange = (e) => {
      const file = e.target.files[0]
      setImage(file)
    }

    const handleUpload = async (event) => {
      event.preventDefault()
      const username = localStorage.getItem("username")
      const userId = localStorage.getItem("userId")
      console.log(userId)
      const res = await axios.post(`${imagesURL}/uploadImagePost?username=${username}`, { image }, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        },

      })
      const res2 = await axios.post(`${imagesURL}/getObj`, {
        key: res.data
      })
      const res3 = await axios.post(`${catalogURL}/addPost`, {
        images: [res2.data],
        user: {
          userId: userId,
          username: username,
          avatar: profileImage
        },
        content: text
      })

      const res4 = await axios.post(`${usersURL}/addPost/${userId}`, {
        post_id: res3.data
      }).then(() => {
        setShouldUpdate(!shouldUpdate)
      })

      handleClose()
    }

    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal__header">
            <Avatar
              alt="Profile Image"
              src={profileImage}
              sx={{ width: 56, height: 56, border: "2px solid #FFD700" }}
            />
            <h1>
              {username.charAt(0).toUpperCase() + username.slice(1)}
            </h1>
            <button onClick={handleClose} className="close_button">
              <CgClose size={24} />
            </button>
          </div>
          <div className="modal__content">
            <textarea value={text} onChange={(e) => {
              setText(e.target.value)
            }} type="text" placeholder="Sobre o que você quer falar?" className="new__post__input" />
            {image && <img src={URL.createObjectURL(image)} alt="" className="post__modal__image" />}
          </div>
          <input type="file" ref={fileInput} onChange={handleFileChange} style={{ display: "none" }} />
          <button onClick={
            handleFileInput

          } aria-label="Adicionar Mídia" title="Adicionar Mídia" className="media__button">
            <IoCameraOutline size={24} />
          </button>
          <div className="modal__footer">
            <button onClick={handleUpload} className={"modal__footer__button"}>Publicar</button>
          </div>
        </Box>
      </Modal>
    )
  }

  const sortedPosts = allPosts.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
  return (
    <>
      <div className="feed-home">
        <ModalCreatePost />
        <div className="feed-home__container">
          <div className="sidebar">
            <button className="sidebar__buttons">
              <LiaCatSolid size={24} />
              <h2>Feed</h2>
            </button>
            <NavLink to={`/profile/${username}`} className="sidebar__buttons" >
              <button className="sidebar__buttons">
                <Avatar
                  alt="Perfil "
                  src={profileImage}
                  sx={{ width: 40, height: 40, border: "2px solid #FFD700" }}
                />
                <h2>Perfil</h2>
              </button>
            </NavLink>
            <h1 className="sidebar__title">Cat of the day!🐱</h1>
            {catImage && <img src={catImage} alt="" className="cat__image" />}
          </div>
          <div className="feed-home__container__content">
            <CreateNewPost />
            {sortedPosts && sortedPosts.map((post) => {
              if (post.deletedAt) {
                return null
              }
              return <Post key={post.postId} post={post} pfpImage={profileImage} />
            })}
          </div>
        </div>
      </div>
    </>
  )
}