import React from "react";
import './Register.css';
import { useState } from "react";
import axios from 'axios';
import dotenv from 'dotenv';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   getLatLng,
// } from 'react-places-autocomplete';
// import { Resend } from 'resend';
import toast, { Toaster } from 'react-hot-toast';
import { IoIosEyeOff, IoIosEye } from "react-icons/io";

dotenv.config();
export default function Register() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  // const [address, setAddress] = useState('');

  // const handleSelect = async value => {
  //   const results = await geocodeByAddress(value);
  //   const latLng = await getLatLng(results[0]);
  //   setAddress(latLng);
  // }

  const apibase = 'https://gataria-users.rawdevops.io'

  const handleRegister = (e) => {
    e.preventDefault();
    const userData = {
      name: name,
      email: email,
      username: user,
      password: password
    }
    axios.post(`${apibase}/auth/register`, userData).then((response) => {
      let notify = () => toast.success('Usuário cadastrado com sucesso')
      notify()
      // sendEmail()
    }).catch((error) => {
      let notify = () => toast.error(error.response.data.message || 'Erro ao cadastrar usuário')
      notify()
      // console.log(error)
    })
  }

  return (
    <div className="Register" style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <h1>Cadastro</h1>
      <form onSubmit={handleRegister} className="formRegister">

        <label htmlFor="name">Nome Completo</label>
        <input onChange={(e) => {
          setName(e.target.value)
        }} type="text" id="name" name="name" />

        {
          /*<label htmlFor="residence">Residência</label>
          <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input {...getInputProps({ placeholder: "Digite a cidade" })} />

            <div>
              {loading ? <div>...carregando</div> : null}

              {suggestions.map(suggestion => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff"
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
        */}

        {/* Auto complete city, state and country */}
        <label htmlFor="email">Email</label>
        <input onChange={(e) => {
          setEmail(e.target.value)
        }} type="email" id="email" name="email" />
        <label htmlFor="email">Usuário</label>
        <input onChange={(e) => {
          setUser(e.target.value)
        }} type="text" id="user" name="user" />

        <label htmlFor="password">Senha</label>
        <div className="PasswordWrapper">
          <input onChange={(e) => {
            setPassword(e.target.value);
          }} type={isVisible ? 'text' : 'password'} id="password" name="password" />
          <button className="EyeButton" type="button" onClick={() => setIsVisible(!isVisible)}>
            {
              isVisible ? <IoIosEye size={25} /> : <IoIosEyeOff size={25} />
            }
          </button>
        </div>

        <button className="buttonSubmit" type="submit">Cadastrar</button>
      </form>
      <Toaster />
    </div>
  )
}
