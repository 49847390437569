import { useEffect } from "react";
import React from "react";
import toast, { Toaster } from 'react-hot-toast';
import FeedHome from "./Feed/FeedHome";

function Home() {

  useEffect(() => {
    if (localStorage.getItem("token") && !sessionStorage.getItem("notified")) {
      let notify = () => {
        toast.success('Usuário logado com sucesso')
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
      }
      notify().then(() => {
        window.location.reload();
      })
      sessionStorage.setItem("notified", true);
    }
  }, []);

  const isAuth = () => {
    return localStorage.getItem("token") !== null;
  }

  return (
    <>
      {isAuth() ? (<FeedHome />) : (<>
        <h1
          style={{
            color: "black",
            fontSize: "80px",
            textAlign: "center",
            fontFamily: "serif",
          }}
        >
          Gataria <span role="img" aria-label="dog">🐾</span>
        </h1>
        <p
          style={{
            color: "black",
            fontSize: "50px",
            textAlign: "center",
            fontFamily: "serif",
          }}
        >
          Te ajudamos adotar um gatinho!
        </p>
      </>)}
      <Toaster />
    </>
  );
}

export default Home;
