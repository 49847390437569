import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  NavLink,
  Switch,
  Route,
} from "react-router-dom";

import AllCats from "./components/AllCats/AllCats";
import AddCats from "./components/AddCats/AddCats";
import Home from "./components/Home/Home";
import LoginPage from "./components/LoginPage/LoginPage";
import Register from "./components/RegisterPage/Register";
import AnalyticsPage from "./components/Analytics/Analytics";
import ProfilePage from "./components/Profile/ProfilePage";

function App() {
  const VerifyAuth = () => {
    let token = localStorage.getItem('token')
    return token ? true : false
  }

  const isAdmin = () => {
    let token = localStorage.getItem('token');
    if (!token) return false;

    try {
      let decoded = JSON.parse(atob(token.split('.')[1]));
      return decoded.role === 'admin' ? true : false;
    } catch (e) {
      console.error('Invalid token:', e);
      return false;
    }
  }

  const Separator = () => {
    return (
      <div className="separator">
        <h1>
          <span role="img" aria-label="dog">🐾</span>
        </h1>
      </div>
    )
  }
  const AuthRoutes = () => {
    return (
      <Switch>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/">
          <Home />
        </Route>
        <Route path="*">
          <h1>
            404 - Página não encontrada
          </h1>
        </Route>
      </Switch>
    )
  }
  const ProtectedRoutes = () => {
    return (
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/allcats">
          <AllCats />
        </Route>
        <Route path="/addcats">
          <AddCats />
        </Route>
        <Route path="/profile/:username">
          <ProfilePage />
        </Route>
        {isAdmin() ? (
          <Route path="/analytics">
            <AnalyticsPage />
          </Route>
        ) : null}
        <Route path="/*">
          <h1>
            404 - Página não encontrada
          </h1>
        </Route>
      </Switch>
    );
  }

  return (
    <Router>
      <div className="App">
        <header>
          <nav>
            <ul>
              <li>
                <NavLink to="/" exact>
                  Início
                </NavLink>
              </li>
              {VerifyAuth() ? (
                <>
                  <li>
                    <NavLink to="/allcats">Ver Gatos</NavLink>
                  </li>
                  <li>
                    <NavLink to="/addcats">Adicionar Gatos</NavLink>
                  </li>
                </>
              ) : null}
              <Separator />
              {!VerifyAuth() ? (
                <>
                  <li>
                    <NavLink to="/login">Login</NavLink>
                  </li>
                  <li>
                    <NavLink to="/register">Cadastro</NavLink>
                  </li>
                </>
              ) : (
                <>
                  {isAdmin() ? (
                    <li>
                      <NavLink to="/analytics">Analytics</NavLink>
                    </li>
                  ) : null}
                  <li>
                    <NavLink to='/logout' onClick={() => {
                      localStorage.removeItem('token')
                      window.location.reload()
                      sessionStorage.removeItem("notified");
                    }}>Sair</NavLink>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </header>
        <main>
          {VerifyAuth() ? <ProtectedRoutes /> : <AuthRoutes />}
        </main>
      </div>
    </Router>
  );
}

export default App;
