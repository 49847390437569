// services/socketService.js
import io from "socket.io-client";
import { dev } from "../config/envs";

// const socket = io("http://localhost:3010"); // Adjust with your backend URL
const originalUrl = dev.catalogAPIurl; // Assuming this is "http://localhost:3010/catalog"
const parsedUrl = new URL(originalUrl);
parsedUrl.pathname = '';
const newUrl = parsedUrl.href;

const socket = io(newUrl);

export const requestLikeCount = (postId) => {
  socket.emit("requestLikeCount", postId);
  return new Promise((resolve, reject) => {
    socket.on("likeCount", (count) => {
      resolve(count);
    });
  });
};

export const sendLike = (postId) => {
  socket.emit("like", postId);
  return new Promise((resolve, reject) => {
    socket.on("likeCountUpdated", (data) => {
      if (data.postId === postId) {
        resolve(data.count);
      }
    });
  });
};

export const sendDislike = (postId) => {
  socket.emit("dislike", postId);
  return new Promise((resolve, reject) => {
    socket.on("likeCountUpdated", (data) => {
      if (data.postId === postId) {
        resolve(data.count);
      }
    });
  });
}

export default socket;