import React from "react"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { useEffect, useState, useCallback } from "react"
import socket, { requestLikeCount, sendLike, sendDislike } from "../../../services/socketService"
import {
  Avatar,
  Typography as Span
} from "@mui/material"
import LikeButton from "../Buttons/LikeButton"
import { IoSend } from "react-icons/io5"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import axios from "axios"
import { dev } from "../../../config/envs"

export const Post = (post) => {
  console.log(post.post)
  const [likeCount, setLikeCount] = useState(post.post.interactions.likes.total);
  useEffect(() => {
    // Request like count on component mount
    requestLikeCount(postId);

    // Listen for like count updates
    socket.on("likeCount", (count) => {
      setLikeCount(count);
    });

    socket.on("likeCountUpdated", (data) => {
      if (data.postId === postId) {
        setLikeCount(data.count);
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off("likeCount");
      socket.off("likeCountUpdated");
    };
  }, []);
  const props = post.post
  const pfpImage = localStorage.getItem("avatar")
  const postId = post.post._id
  const myId = localStorage.getItem("userId")
  console.log(post)
  const likesArray = props.interactions.likes.users
  const [likes, setLikes] = useState(props.interactions.likes.total)
  const [commentText, setComment] = useState("")
  const date = new Date(post.createdAt)
  const isLiked = props.interactions.likes.users.includes(myId)
  const [liked, setLiked] = useState(isLiked)
  const likeRoute = isLiked ? "unlikePost" : "likePost"
  const basePostsUrl = dev.catalogAPIurl

  const handleComment = (event) => {
    event.preventDefault()
    const comment = {
      comment: commentText,
      user: {
        userId: localStorage.getItem("userId"),
        username: localStorage.getItem("username"),
        avatar: pfpImage
      }
    }
    axios.patch(`https://gataria-catalog.rawdevops.io/catalog/commentPost/${postId}`, {
      comment: comment
    }).then((res) => {
      console.log(res.data)
      // setShouldUpdate(!shouldUpdate)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleLike = useCallback(() => {
    axios.patch(`${basePostsUrl}/likePost/${postId}`, {
      user_id: localStorage.getItem("userId")
    }).then((res) => {
      setLiked(!liked)
    }).catch((err) => {
      console.log(err)
    })
  })

  const handleDislike = useCallback(() => {
    axios.patch(`${basePostsUrl}/unlikePost/${postId}`, {
      user_id: localStorage.getItem("userId")
    }).then((res) => {
      setLiked(!liked)
    }).catch((err) => {
      console.log(err)
    })
  })

  return (
    <div className="post">
      <div className="post__header">
        <Link to={`profile/${props.user.username}`}>
          <Avatar
            alt="Remy Sharp"
            src={props.user.avatar}
            sx={{ width: 56, height: 56, border: "2px solid #FFD700" }}
          />
        </Link>
        <div className="post__header__info">
          <h2 className="post__username">
            {props.user.username}
          </h2>
          <span className="post__header__date">
            {date.toLocaleDateString()}
          </span>
        </div>
      </div>
      <div className="post__content">
        <p>
          {props.content}
        </p>
        <img className="post__image" src={
          props.images[0].imageUrl
        } alt="" />
      </div>
      <div className="post__footer">
        <div className="post__info">
          <button onClick={() => {
            liked ? handleDislike() : handleLike()
            liked ? sendDislike(postId) : sendLike(postId)
          }} className="like_button">
            {liked ? <FaHeart color="#ff0000" size={25} /> : <FaRegHeart size={25} />}
          </button>
          <Span variant="h6">
            {likeCount} curtidas
          </Span>
          <Span variant="h6">
            {props.interactions.comments.total} comentários
          </Span>
        </div>
        {/* <LikeButton handleLike={handleLike} handleDislike={handleDislike} isLiked={isLiked} /> */}
        <div className="post__footer__comments">
          <h2>Comentários</h2>
          {/* Area to the user create a new commment */}
          <div className="comment">
            <Avatar
              alt="Remy Sharp"
              src={pfpImage}
              sx={{ width: 56, height: 56, border: "2px solid #FFD700" }}
            />
            <input value={commentText} onChange={(event) => {
              setComment(event.target.value)
            }} type="text" placeholder="Digite um comentário" className="new__comment" />
            <button onClick={handleComment} className="comment__button">
              <IoSend size={24} />
            </button>
          </div>
          {props.interactions.comments.comments.map((comment) => {
            return (
              <div key={
                comment.id
              } className="comment">
                <Avatar
                  alt="Remy Sharp"
                  src={comment.user.avatar}
                  sx={{ width: 56, height: 56, border: "2px solid #FFD700" }}
                />
                <div className="comment__content">
                  <Span variant="h6">
                    {/* first capital letter  */}
                    {
                      comment.user.username.charAt(0).toUpperCase() + comment.user.username.slice(1)
                    }
                    <Span variant="caption">
                      {" - "} {new Date(comment.postDate).toLocaleDateString('pt-BR')}
                    </Span>
                  </Span>
                  <p>{comment.comment}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}