import React, { useEffect } from "react";
import { useState, useCallback } from "react";
import {
  Avatar,
  Button,
  Typography as Span
} from "@mui/material"
import { Modal, Box } from "@mui/material"
import "./PostModal.css"
import { FaHeart, FaTrash, FaRegHeart, FaCheck } from "react-icons/fa";
import { IoSend } from "react-icons/io5";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import socket, { requestLikeCount, sendLike, sendDislike } from "../../../services/socketService";

// configuração de variáveis de ambientep
import { dev } from "../../../config/envs";
const basePostsUrl = dev.catalogAPIurl;

export default function PostModal({ post, open, handleClose }) {
  const [likeCount, setLikeCount] = useState(0);
  useEffect(() => {
    // Request like count on component mount
    requestLikeCount(postId);

    // Listen for like count updates
    socket.on("likeCount", (count) => {
      setLikeCount(count);
    });

    socket.on("likeCountUpdated", (data) => {
      if (data.postId === postId) {
        setLikeCount(data.count);
      }
    });

    // Cleanup on component unmount
    return () => {
      socket.off("likeCount");
      socket.off("likeCountUpdated");
    };
  }, []);

  const comments = post.interactions.comments.comments
  const myId = localStorage.getItem("userId")
  const postId = post._id
  const [comment, setComment] = useState("")
  const isLiked = post.interactions.likes.users.includes(myId)
  const [liked, setLiked] = useState(isLiked)
  const likeRoute = isLiked ? "unlikePost" : "likePost"
  const [deleteOpen, setDeleteOpen] = useState(false)
  const isMyPost = post.user.userId === myId

  const handleDeleteOpen = () => {
    setDeleteOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
  }

  const DeletePostModal = ({ deleteOpen, handleDeleteClose }) => {
    return (
      <Modal
        open={deleteOpen}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            height: "30%",
            width: "30%",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "10px",
            border: "none",
            outline: "none",
          }}
        >
          <div className="buttons">
            <h2>Excluir post?</h2>
            <button onClick={handleDeleteClose} className="cancelar__botao">
              Cancelar
            </button>
            <button onClick={handleExcludePost} className="confirmar__botao">
              Confirmar
            </button>
          </div>
        </Box>
      </Modal>
    )
  }

  const handleLike = useCallback(() => {
    axios.patch(`${basePostsUrl}/likePost/${postId}`, {
      user_id: myId
    }).then((res) => {
      setLiked(!liked)
    }).catch((err) => {
      console.log(err)
    })
  })

  const handleDislike = useCallback(() => {
    axios.patch(`${basePostsUrl}/unlikePost/${postId}`, {
      user_id: myId
    }).then((res) => {
      setLiked(!liked)
    }).catch((err) => {
      console.log(err)
    })
  })
  const handleComment = (e) => {
    setComment(e.target.value)
  }

  const handleCommentSubmit = () => {
    const commentData = {
      comment,
      user: {
        userId: myId,
        avatar: localStorage.getItem("avatar"),
        username: localStorage.getItem("username")
      }
    }
    axios.patch(`${basePostsUrl}/commentPost/${postId}`, {
      comment: commentData
    }).then((res) => {
      console.log(res)
      setComment("")
      toast.success("Comentário adicionado com sucesso")
    }).catch((err) => {
      toast.error("Erro ao adicionar comentário")
    })
  }

  const handleExcludePost = () => {
    axios.patch(`${basePostsUrl}/deletePost/${postId}`).then((res) => {
      handleDeleteClose()
      handleClose()
      toast.success("Post excluído com sucesso")

    }).catch((err) => {
      console.log(err)
      toast.error("Erro ao excluir post")
    })
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            height: "80%",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "10px",
            border: "none",
            outline: "none",
          }}
        >
          <div className="post_modal_wrapper">
            <img className="post__image" src={post.images[0].imageUrl} alt="" />
            <div className="post__infos">
              <div className="user_avatar">
                <div className="infos">
                  <Avatar
                    sx={{
                      width: 50,
                      height: 50,
                      border: "2px solid #ffbf6b",
                    }}
                    src={post.user.avatar}
                  />
                  <a href={
                    `/profile/${post.user.username}`
                  } variant="h6">{post.user.username}</a>
                </div>
                <DeletePostModal deleteOpen={deleteOpen} handleDeleteClose={handleDeleteClose} />
                {isMyPost && (
                  <Button onClick={handleDeleteOpen} variant="outlined" sx={{
                    color: "#ff0000",
                    border: "none",
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    lineHeight: "0",
                    fontWeight: "bold",
                    padding: "0",
                    minWidth: "30px",
                  }}>
                    <FaTrash />
                  </Button>
                )}
              </div>
              <div className="comments__section">
                {comments.map((comment) => {
                  return (
                    <div className="comment__modal">
                      <Avatar
                        sx={{
                          width: 30,
                          height: 30,
                          border: "2px solid #ffbf6b",
                        }}
                        src={comment.user.avatar}
                      />
                      <div className="comment__infos">
                        <a href={
                          `/profile/${comment.user.username}`
                        } variant="h6">
                          <strong>{comment.user.username}</strong>
                        </a>
                        <Span variant="body1">{comment.comment}</Span>
                      </div>
                    </div>
                  )
                })}

              </div>
              <div className="profile_modal_footer">
                <div className="interactions__section">
                  <button onClick={() => {
                    liked ? handleDislike() : handleLike()
                    liked ? sendDislike(postId) : sendLike(postId)
                  }} className="like_button">
                    {liked ? <FaHeart color="#ff0000" size={25} /> : <FaRegHeart size={25} />}
                  </button>
                  <Span variant="h6">{likeCount} curtidas</Span>
                  <Span variant="h6">{post.interactions.comments.total} comentários</Span>
                </div>
                <div className="new__comment">
                  <input value={comment} onChange={handleComment} type="text" placeholder="Adicione um comentário" />
                  <button onClick={handleCommentSubmit} className="send__button">
                    <IoSend />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Toaster />
    </>
  )
}