const prod = {
    catalogAPIurl: "http://gataria.example/catalog",
    imagesAPIurl: "http://gataria.example/images"
};

export const dev = {
    catalogAPIurl: "https://gataria-catalog.rawdevops.io/catalog",
    imagesAPIurl: "https://gataria-images.rawdevops.io/images",
    usersAPIurl: "https://gataria-users.rawdevops.io"
};

const config =
    process.env.REACT_APP_STAGE === "prod"
        ? prod
        : process.env.REACT_APP_STAGE === "dev"
            ? dev
            : dev;

export default {
    ...config
};
