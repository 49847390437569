import React from "react";
import { Button } from "@mui/material";

const LikeButton = React.memo(({ handleLike, handleDislike, isLiked }) => {
  return (
    <Button
      onClick={isLiked ? handleDislike : handleLike}
      variant={isLiked ? "contained" : "outlined"}
    >
      {isLiked ? "Dislike" : "Like"}
    </Button>
  );
});

export default LikeButton;